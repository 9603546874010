/* Footer Styling */
.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; 
    line-height: 60px;
    background-color: #f5f5f5;
  }

  .copyright{
      text-align: left;
      margin-left: 30px;
  }

  .footerIcon{
      color:  #7aa44d;
      padding-left: 10px;
      float: right;
      margin-right: 15px;
      margin-top: 15px;
  }