#background-static{
    background: #fff;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.header-img{
    height: 100vh;
    width: 100vw;
}
.introduction{
    margin-top: 100px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
    width: 500px;
    border: 2px solid #7aa44d;
    border-radius: 25px;
    padding: 25px;
    background-color: #E8E7E3;
    color: #313130;
}